import { jsx as _jsx } from "react/jsx-runtime";
import { View, StyleSheet, Platform, } from 'react-native';
import { WebView } from 'react-native-webview';
import React from 'react';
import '../../Tools/Web/apiPost';
export const AnnounceScreen = ({}) => {
    return (_jsx(View, { style: styles.full, children: Platform.OS === 'web' ? (_jsx("iframe", { src: "https://aiqipaiwang.com/announce/", style: { width: '100%', height: '100%', border: 'none' }, title: "WebView" })) : (_jsx(WebView, { source: { uri: 'https://aiqipaiwang.com/announce/' }, style: { flex: 1 } })) }));
};
const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 0,
    },
    full: {
        flex: 1,
    },
    modalView: {
        width: '90%',
        minHeight: '60%',
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        justifyContent: 'center', // 确保内部内容在垂直方向上居中
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#007bff',
        padding: 10,
        width: Platform.OS === 'web' ? '40%' : '100%',
        borderRadius: 0,
        marginBottom: 0,
    },
    text: {
        color: '#ffffff',
    },
    input: Platform.select({
        ios: {
            // iOS平台专用样式
            width: '80%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
        android: {
            // Android平台专用样式
            width: '80%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
        web: {
            // Web平台专用样式
            width: '40%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
        default: {
            // 默认样式
            width: '80%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
    }),
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
        fontSize: 18, // 增加文本字体大小
    },
});
export default AnnounceScreen;
