import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { View, 
// TextInput,
// Button,
StyleSheet, TouchableOpacity, Text, FlatList, Image, Platform, 
//InteractionManager,
//LayoutAnimation,
//UIManager,
RefreshControl, } from 'react-native';
import { useAuth } from '../../Tools/AuthContext';
// import {getToken} from '../../Tools/Web/apiPost';
import { getQuestion, getUrlQuestion } from '../../Tools/Web/apiGet';
//import {useNavigation} from '@react-navigation/native';
import { screenWidth, picRatio } from '../../Tools/config';
import PageSelector from '../PageSelector';
import { parsePageURL, parseStartEndPage } from '../../Tools/utils';
const Item = ({ qp, onPress, isHD, onLayout }) => (_jsx(TouchableOpacity, { onPress: () => onPress(qp), onLayout: onLayout, children: _jsxs(View, { style: styles.item, children: [_jsx(Text, { style: styles.title, children: qp.title }), _jsx(Image, { source: { uri: isHD === true ? qp.image_desktop : qp.image_desktop_small }, style: Platform.OS === 'web' ? styles.desktop_web : styles.desktop })] }) }));
export const QuestionBankScene = ({ navigation }) => {
    const act = useAuth();
    const [items, setItems] = useState([]);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [prevPageUrl, setPrevPageUrl] = useState(null);
    const [isEnabled, setIsEnabled] = useState(true);
    const toggleSwitch = () => setIsEnabled(previousState => !previousState);
    const [countPerPage, setCountPerPage] = useState(10);
    const [maxPage, setMaxPage] = useState(2);
    const [curPage, setCurPage] = useState(1);
    const flatListRef = useRef(null);
    const [isLoadMoreEnabled, setIsLoadMoreEnabled] = useState(true);
    const itemHeightRef = useRef(0); // 记录每个项目的高度
    const scrollPositionRef = useRef(0); // 记录当前滚动位置
    //const [scrollIndex, setScrollIndex] = useState<number | null>(null);
    const [refreshing, setRefreshing] = useState(false);
    const handlePageChange = async (page_id) => {
        if (!act) {
            return;
        }
        //setCurrentPage(page);
        console.log(`Selected Page: ${page_id}`);
        const sePage = parseStartEndPage(prevPageUrl, nextPageUrl, maxPage);
        if (page_id >= sePage.start_page && page_id <= sePage.end_page) //在当前的所有页范围内
         {
            var distance = (page_id - sePage.start_page) * countPerPage;
            console.log(`distance: ${distance}`);
            flatListRef.current?.scrollToIndex({ animated: true, index: distance, viewOffset: 0, viewPosition: 0 });
            setCurPage(page_id);
        }
        else {
            setRefreshing(true);
            const questions = await getQuestion(act, page_id);
            if (!questions) {
                setRefreshing(false);
                return;
            }
            console.log("come here ", page_id);
            //setScrollIndex(0);
            //setIsLoadMoreEnabled(false);
            setItems(prevItems => [...questions.results]);
            setNextPageUrl(questions.next);
            setPrevPageUrl(questions.previous);
            flatListRef.current?.scrollToIndex({
                index: 0,
                animated: false,
            });
            setRefreshing(false);
            setCurPage(page_id);
        }
    };
    const loadMoreItems_next = async () => {
        console.log('loadMoreItems_next ', nextPageUrl);
        if (nextPageUrl && act && isLoadMoreEnabled) {
            try {
                const questions = await getUrlQuestion(nextPageUrl, act);
                setItems(prevItems => [...prevItems, ...questions.results]);
                setNextPageUrl(questions.next);
                console.log('questions', questions);
            }
            catch (error) {
                console.error('Failed to loadMoreItems_next', error);
            }
        }
    };
    // const loadMoreItems_prev = async () => {
    //   console.log('loadMoreItems_prev ', prevPageUrl);
    //   if (prevPageUrl && act && isLoadMoreEnabled) {
    //     try {
    //       const questions = await getUrlQuestion(prevPageUrl, act);
    //       setIsLoadMoreEnabled(false);
    //       var xxx = questions.results.length;
    //       //setScrollIndex(xxx);
    //       LayoutAnimation.configureNext(LayoutAnimation.Presets.linear, () => {
    //         flatListRef.current?.scrollToIndex({
    //           index: xxx, // 你希望滚动到的索引位置
    //           animated: false,
    //         });
    //       });
    //       setItems(prevItems => [...questions.results, ...prevItems]);
    //       setPrevPageUrl(questions.previous);
    //       setTimeout(() => {
    //         setIsLoadMoreEnabled(true);
    //       }, 1000);
    //       console.log('questions', questions);
    //     } catch (error) {
    //       console.error('Failed to loadMoreItems_prev', error);
    //     }
    //   }
    // };
    const onRefresh = async () => {
        console.log("onRefresh");
        if (prevPageUrl && act) {
            try {
                setRefreshing(true);
                const questions = await getUrlQuestion(prevPageUrl, act);
                if (!questions) {
                    setRefreshing(false);
                    return;
                }
                //setIsLoadMoreEnabled(false);
                //var xxx = questions.results.length;
                var page_id = parsePageURL(prevPageUrl);
                setItems(prevItems => [...questions.results, ...prevItems]);
                // setItems(prevItems => {
                //   const updatedData = [...questions.results, ...prevItems];
                //   requestAnimationFrame(() => {
                //       if (flatListRef.current) {
                //           flatListRef.current.scrollToIndex({
                //               index: xxx, // 假设每次都加载相同数量的新数据
                //               animated: false
                //           });
                //       }
                //   });
                //   return updatedData;
                // });
                setPrevPageUrl(questions.previous);
                setRefreshing(false);
                setCurPage(page_id);
            }
            catch (error) {
                console.error('Failed to onRefresh', error);
            }
        }
    };
    const handlePress = (qp) => {
        console.log(`Pressed item with id: ${qp.id} and title: ${qp.title}`);
        navigation.navigate('QuestionDetail', qp);
    };
    // useEffect(() => {
    //   if (scrollIndex !== null) {
    //     setScrollIndex(null);
    //   }
    // }, [items]);
    useEffect(() => {
        // 定义 fetchData 函数
        const fetchData = async () => {
            if (act) {
                try {
                    setRefreshing(true);
                    const questions = await getQuestion(act, 1);
                    if (!questions) {
                        setRefreshing(false);
                        return;
                    }
                    const count_per_page = questions.results.length;
                    setCountPerPage(count_per_page);
                    const count_all = questions.count;
                    console.log("count_per_page ", count_per_page);
                    console.log("count_all", count_all);
                    const max_page = Math.ceil(count_all / count_per_page);
                    setMaxPage(max_page);
                    // 直接更新状态，不依赖于外部的 addItems
                    setItems(prevItems => [...prevItems, ...questions.results]);
                    setNextPageUrl(questions.next);
                    setPrevPageUrl(questions.previous);
                    setRefreshing(false);
                    setCurPage(1);
                    console.log('questions', questions);
                }
                catch (error) {
                    console.error('Failed to fetch questions:', error);
                }
            }
        };
        // UIManager.setLayoutAnimationEnabledExperimental &&
        //   UIManager.setLayoutAnimationEnabledExperimental(true);
        fetchData();
    }, []); // 依赖项仅包括 act，确保只在 act 变化时重新获取数据
    const onScroll = (event) => {
        scrollPositionRef.current = event.nativeEvent.contentOffset.y;
        const sePage = parseStartEndPage(prevPageUrl, nextPageUrl, maxPage);
        const pageHeight = countPerPage * itemHeightRef.current;
        const page_count = Math.floor(scrollPositionRef.current / pageHeight);
        var page_id = sePage.start_page + page_count;
        if (page_id < sePage.start_page) {
            page_id = sePage.start_page;
        }
        if (page_id > sePage.end_page) {
            page_id = sePage.end_page;
        }
        //console.log("onScroll ", page_id);
        setCurPage(page_id);
    };
    const onItemLayout = useCallback((event) => {
        const { height } = event.nativeEvent.layout;
        itemHeightRef.current = height;
    }, []);
    // const onScrollToIndexFailed = (info: { index: number, highestMeasuredFrameIndex: number, averageItemLength: number }) => {
    //   const waitTime = Platform.OS === 'ios' ? 500 : 100;
    //   setTimeout(() => {
    //     flatListRef.current?.scrollToIndex({
    //       index: info.index,
    //       animated: false,
    //     });
    //   }, waitTime);
    // };
    return (_jsxs(View, { style: styles.container, children: [_jsx(FlatList, { ref: flatListRef, data: items, renderItem: ({ item }) => _jsx(Item, { qp: item, onPress: handlePress, isHD: isEnabled, onLayout: onItemLayout }), keyExtractor: item => item.id.toString(), 
                //onScrollToIndexFailed={onScrollToIndexFailed}
                onEndReached: isLoadMoreEnabled ? loadMoreItems_next : null, onEndReachedThreshold: 0.8, 
                // onStartReached={isLoadMoreEnabled ? loadMoreItems_prev : null}
                // onStartReachedThreshold={0}
                onScroll: onScroll, 
                //initialScrollIndex={0}
                refreshControl: _jsx(RefreshControl, { refreshing: refreshing, onRefresh: onRefresh }) }), _jsx(View, { style: styles.page, children: _jsx(PageSelector, { initialPage: curPage, maxPage: maxPage, onPageChange: handlePageChange }) })] }));
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        //marginTop: StatusBar.currentHeight || 0,
    },
    item: {
        backgroundColor: '#ffffff',
        padding: 0,
        marginVertical: 0,
        marginHorizontal: 0,
        justifyContent: 'center',
        alignItems: 'center', // 这会水平居中子视图
    },
    title: {
        // fontFamily: 'Roboto',
        fontSize: 28,
        fontWeight: 'bold',
    },
    desktop: {
        width: screenWidth,
        height: screenWidth * picRatio,
    },
    desktop_web: {
        width: screenWidth * 0.8,
        height: screenWidth * picRatio * 0.8,
    },
    xun: {
        fontSize: 24,
    },
    page: {
        position: 'absolute',
        top: 5,
        right: 5,
        padding: 2, // 增加点击区域
    },
    switchHDParent: {
        position: 'absolute',
        top: 5,
        right: 15,
        padding: 2, // 增加点击区域
    },
    switchHD: {},
});
export default QuestionBankScene;
