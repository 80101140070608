import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { View, 
//   TextInput,
//   Button,
StyleSheet, TouchableOpacity, Text, 
//   StatusBar,
FlatList,
//   Image,
//   Dimensions,
 } from 'react-native';
import { useAuth } from '../../Tools/AuthContext';
import { getExamPapersByAuthorID, dailyExamPaper } from '../../Tools/Web/apiPost';
// import {LineChart} from 'react-native-chart-kit';
import { parseJwt, formatDateTime } from '../../Tools/utils';
const Item = ({ epp, onPress }) => (_jsx(TouchableOpacity, { onPress: () => onPress(epp), style: styles.touchableArea, children: _jsxs(View, { style: styles.item, children: [_jsx(View, { style: styles.scoreContainer, children: _jsxs(Text, { style: styles.score, children: [epp.score, "\u5206"] }) }), _jsx(View, { style: styles.dateContainer, children: _jsx(Text, { style: styles.date, children: formatDateTime(epp.created_date) }) })] }) }));
export const ExamRecordScene = ({ navigation /*route*/ }) => {
    //return <Text>This is {route.params.name}'s profile</Text>;
    const act = useAuth();
    //   if (!act) {
    //     return <Text>This is ExamRecord</Text>;
    //   }
    //   const {
    //     accessToken,
    //     //setAccessToken,
    //     //refreshToken,
    //     //setRefreshToken,
    //     dataFlag,
    //     //setDataFlag,
    //   } = act;
    const [examPapers, setExamPapers] = useState([]);
    const [avgAllScore, setAvgAllScore] = useState(0);
    const [avg10Score, setAvg10Score] = useState(0);
    useEffect(() => {
        // 直接定义 fetchData 函数
        const fetchData = async () => {
            if (act && act.accessToken !== '') {
                const decoded = parseJwt(act.accessToken);
                const user_id = decoded.user_id;
                const examPapersX = await getExamPapersByAuthorID(user_id, act);
                console.log('examPapers', examPapersX);
                if (examPapersX && examPapersX.length > 0) {
                    const updatedExamPapers = examPapersX
                        .map(paper => ({
                        // 把字段 author_id 改成 author
                        ...paper,
                        author: paper.author_id,
                        author_id: undefined, // 将原来的 author_id 字段设为 undefined
                    }))
                        .map(({ author_id, ...rest }) => rest);
                    console.log('updatedExamPapers', updatedExamPapers);
                    setExamPapers(updatedExamPapers);
                    // 计算历史均分
                    const averageScoreAll = updatedExamPapers
                        .map(paper => paper.score)
                        .reduce((acc, curr, _, arr) => acc + curr / arr.length, 0);
                    setAvgAllScore(averageScoreAll);
                    // 计算最近10场均分
                    const last10Papers = updatedExamPapers.slice(0, 10);
                    const averageScoreLast10 = last10Papers
                        .map(paper => paper.score)
                        .reduce((acc, curr, _, arr) => acc + curr / arr.length, 0);
                    setAvg10Score(averageScoreLast10);
                }
            }
        };
        // 初始化
        setExamPapers([]);
        setAvgAllScore(0);
        setAvg10Score(0);
        // 调用 fetchData
        fetchData();
    }, [act?.dataFlag]); // 依赖于 act
    const handlePress = (epp) => {
        console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
        //const {accessToken, setAccessToken, refreshToken, setRefreshToken} = act;
        if (act?.accessToken === '') {
            act.setNeedLogin(true);
            return;
        }
        navigation.navigate('ExamResult', epp);
        //console.log(`Pressed item with id: ${id} and title: ${title}`);
        // 根据需要执行更多操作
        // navigation.navigate('QuestionDetail', {
        //   id: id,
        //   title: title,
        //   image_desktop: image_desktop,
        //   xun: xun,
        // });
    };
    const handleStart = () => {
        //const {accessToken, setAccessToken, refreshToken, setRefreshToken} = act;
        if (act?.accessToken === '') {
            act.setNeedLogin(true);
            return;
        }
        navigation.navigate('ExamPaper');
    };
    const handleDaily = async () => {
        if (act?.accessToken === '') {
            act.setNeedLogin(true);
            return;
        }
        if (act) {
            const depr = await dailyExamPaper(act);
            console.log('depr: ', depr);
            if (depr.option_id == -1) {
                navigation.navigate('ExamPaperDaily', depr);
            }
            else {
                navigation.navigate('ExamResultDaily', depr);
            }
        }
        //navigation.navigate('ExamPaper');   
    };
    return (_jsxs(View, { style: styles.container, children: [_jsx(Text, { style: styles.date2 }), _jsx(Text, { style: styles.date2 }), _jsx(FlatList, { data: examPapers, renderItem: ({ item }) => _jsx(Item, { epp: item, onPress: handlePress }), keyExtractor: item => item.id.toString(), 
                // onEndReached={loadMoreItems}
                onEndReachedThreshold: 0.2 }), _jsxs(View, { style: styles.item, children: [_jsx(View, { style: styles.dateContainer2, children: _jsx(Text, { style: styles.date2, children: "\u8FD110\u573A\u5747\u5206" }) }), _jsx(View, { style: styles.scoreContainer2, children: _jsx(Text, { style: styles.score2, children: avg10Score.toFixed(2) }) })] }), _jsxs(View, { style: styles.item, children: [_jsx(View, { style: styles.dateContainer2, children: _jsx(Text, { style: styles.date2, children: "\u5386\u53F2\u5747\u5206" }) }), _jsx(View, { style: styles.scoreContainer2, children: _jsx(Text, { style: styles.score2, children: avgAllScore.toFixed(2) }) })] }), _jsx(TouchableOpacity, { onPress: handleDaily, style: styles.buttonDaily, children: _jsx(Text, { style: styles.text, children: "\u6BCF\u65E5\u4E00\u9898" }) }), _jsx(TouchableOpacity, { onPress: handleStart, style: styles.button, children: _jsx(Text, { style: styles.text, children: "\u5B66\u4E60\u6D4B\u8BD5" }) })] }));
};
// 样式
const styles = StyleSheet.create({
    container: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        flexDirection: 'column',
        width: '100%', // 使容器填满横向空间
    },
    touchableArea: {
        width: '100%', // 确保TouchableOpacity填满一行
    },
    item: {
        flexDirection: 'row',
        //justifyContent: 'space-between', // 子元素在主轴线上均匀分布
        width: '100%', // 确保item填满一行
    },
    scoreContainer: {
        flex: 3,
        justifyContent: 'center', // 在容器内部垂直居中
        // alignItems: 'center', // 在容器内部水平居中
    },
    dateContainer: {
        flex: 7,
        justifyContent: 'center', // 在容器内部垂直居中
        // alignItems: 'center', // 在容器内部水平居中
    },
    score: {
        fontWeight: 'bold',
        fontSize: 32,
        color: '#4A90E2',
        textAlign: 'center',
    },
    date: {
        color: '#666',
        fontSize: 24,
        textAlign: 'center',
    },
    scoreContainer2: {
        flex: 6,
        justifyContent: 'center', // 在容器内部垂直居中
        // alignItems: 'center', // 在容器内部水平居中
    },
    dateContainer2: {
        flex: 4,
        justifyContent: 'center', // 在容器内部垂直居中
        // alignItems: 'center', // 在容器内部水平居中
    },
    score2: {
        fontWeight: 'bold',
        fontSize: 32,
        color: '#4A90E2',
        textAlign: 'center',
    },
    date2: {
        color: '#666',
        fontSize: 24,
        textAlign: 'center',
    },
    buttonDaily: {
        alignItems: 'center',
        backgroundColor: '#007bff',
        padding: 10,
        width: '100%',
        borderRadius: 0,
        marginBottom: 10, // 在按钮底部增加10个像素的空白
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#007bff',
        padding: 10,
        width: '100%',
        borderRadius: 0, // 可选：添加圆角
    },
    text: {
        color: '#ffffff',
    },
});
export default ExamRecordScene;
