import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useContext, useState } from 'react';
import CustomModal from '../Screen/CustomModal';
import CustomModal2 from '../Screen/CustomModal2';
import PrivateModal from '../Screen/PrivateModal';
import ContractModal from '../Screen/ContractModal';
// 创建 Context
const AuthContext = createContext(null);
export const useAuth = () => useContext(AuthContext);
// Provider 组件
export const AuthProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState('');
    const [refreshToken, setRefreshToken] = useState('');
    const [needLogin, setNeedLogin] = useState(false); //默认是不需要登录的，
    const [needLogo, setNeedLogo] = useState(true);
    const [dataFlag, setDataFlag] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [isVisible2, setIsVisible2] = useState(false);
    const [message2, setMessage2] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => { });
    const [isPrivateVisible, setIsPrivateVisible] = useState(false);
    const [isContractVisible, setIsContractVisible] = useState(false);
    const showModal = (message) => {
        setMessage(message);
        setIsVisible(true);
    };
    const hideModal = () => {
        setIsVisible(false);
    };
    const showModal2 = (message, onConfirmCallback) => {
        setMessage2(message);
        setIsVisible2(true);
        setOnConfirm(() => onConfirmCallback); // 存储回调函数
    };
    const hideModal2 = () => {
        setIsVisible2(false);
    };
    const showPrivateModal = () => {
        setIsPrivateVisible(true);
    };
    const hidePrivateModal = () => {
        setIsPrivateVisible(false);
    };
    const showContractModal = () => {
        setIsContractVisible(true);
    };
    const hideContractModal = () => {
        setIsContractVisible(false);
    };
    return (_jsxs(AuthContext.Provider, { value: {
            accessToken,
            setAccessToken,
            refreshToken,
            setRefreshToken,
            needLogin,
            setNeedLogin,
            needLogo,
            setNeedLogo,
            dataFlag,
            setDataFlag,
            showModal,
            hideModal,
            showModal2,
            hideModal2,
            showPrivateModal,
            hidePrivateModal,
            showContractModal,
            hideContractModal,
        }, children: [children, _jsx(CustomModal, { modalVisible: isVisible, modalMessage: message, handleClose: hideModal }), _jsx(CustomModal2, { modalVisible: isVisible2, modalMessage: message2, handleOK: onConfirm, handleCancel: hideModal2 }), _jsx(PrivateModal, { modalVisible: isPrivateVisible }), _jsx(ContractModal, { modalVisible: isContractVisible })] }));
};
