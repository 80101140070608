import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { View, 
//   TextInput,
//   Button,
StyleSheet, TouchableOpacity, Text, 
//   FlatList,
Image, ScrollView, Platform, } from 'react-native';
import { useAuth } from '../../Tools/AuthContext';
// import {getToken} from '../../Tools/Web/apiPost';
// import {getQuestion, getUrlQuestion} from '../../Tools/Web/apiGet';
import { getOneExamPaperForTest } from '../../Tools/Web/apiPost';
//import {useNavigation} from '@react-navigation/native';
import { 
//OptionProps,
SERVER_IP_PORT, actionMap, paiMap, screenWidth, picRatio, } from '../../Tools/config';
import { string_2_numberArray } from '../../Tools/utils';
const Item = ({ qftp, index, option_ids }) => {
    const option_id = option_ids[index];
    const first_id = qftp.options[0].id;
    //const sortedOptions = qftp.options.sort((a, b) => a.order - b.order);
    const sortedOptions = [...qftp.options].sort((a, b) => a.order - b.order);
    const commentexpert_content = qftp.commentexpert_content;
    const commentexpert_author_name = qftp.commentexpert_author_name;
    return (_jsxs(View, { style: styles.item, children: [_jsx(View, { style: styles.image_bg, children: _jsx(Image, { source: { uri: SERVER_IP_PORT + qftp.image_desktop }, style: Platform.OS === 'web' ? styles.desktop_web : styles.desktop_app }) }), _jsxs(View, { style: styles.optionRow, children: [_jsx(Text, { style: styles.column, children: "\u64CD\u4F5C" }), _jsx(Text, { style: styles.columnMid, children: "\u724C" }), _jsx(Text, { style: styles.columnRight, children: "\u6982\u7387" }), _jsx(Text, { style: styles.columnRight, children: "\u9009\u62E9" })] }), sortedOptions.map(option => (_jsxs(View, { style: [
                    styles.optionRow,
                    option.id === first_id ? styles.rowHighlight : {}, // 如果option.id等于first_id，应用高亮样式
                ], children: [_jsx(Text, { style: styles.column, children: actionMap.get(option.action) }), _jsxs(Text, { style: styles.columnMid, children: [paiMap.get(option.pai1), " ", paiMap.get(option.pai2)] }), _jsx(Text, { style: styles.columnRight, children: option.prob }), _jsx(View, { style: styles.columnRight, children: _jsx(TouchableOpacity, { style: styles.radioButton, disabled: true, children: option_id === option.id && (_jsx(View, { style: styles.radioButtonSelected })) }) })] }, option.id))), _jsx(View, { style: styles.commentSection, children: _jsx(Text, { style: styles.commentText, children: `${commentexpert_author_name}: ${commentexpert_content}` }) })] }));
};
export const ExamResultScene = ({ navigation, route }) => {
    const epp = route.params;
    const act = useAuth();
    console.log('question_ids ', epp.question_ids);
    console.log('option_ids ', epp.option_ids);
    console.log('score ', epp.score);
    const [items, setItems] = useState([]);
    const [question_ids_array, set_question_ids_array] = useState([]);
    const [option_ids_array, set_option_ids_array] = useState([]);
    const [message, setMessage] = useState(epp.message);
    //   if (!act) {
    //     //console.log("ccccccccccccccccccccccccccccc");
    //     return <View />;
    //   }
    // Step 1: 设置 question_ids_array 和 option_ids_array
    useEffect(() => {
        const question_ids_array_temp = string_2_numberArray(epp.question_ids);
        const option_ids_array_temp = string_2_numberArray(epp.option_ids);
        set_question_ids_array(question_ids_array_temp);
        set_option_ids_array(option_ids_array_temp);
        if (items.length !== 0) {
            setItems([]); // 只有在需要时才清空数据
        }
    }, [epp]);
    // Step 2: 监控 question_ids_array 和 option_ids_array 的变化
    useEffect(() => {
        const fetchData = async () => {
            if (act && question_ids_array.length > 0 && option_ids_array.length > 0) {
                const questions_temp = await getOneExamPaperForTest(question_ids_array, act);
                console.log('questions_tempxxxxx', questions_temp);
                if (questions_temp) {
                    setItems(questions_temp);
                    if (message) {
                        act?.showModal(message);
                        setMessage(null); // 不再直接修改 epp.message
                    }
                }
            }
        };
        if (act?.accessToken !== '' && question_ids_array.length > 0 && option_ids_array.length > 0) {
            fetchData();
        }
    }, [act?.dataFlag, question_ids_array, option_ids_array]); // 依赖这两个状态的变化
    return (_jsxs(View, { style: styles.container, children: [_jsxs(ScrollView, { contentContainerStyle: styles.scrollViewContent, children: [_jsx(View, { style: styles.title_bg, children: _jsx(Text, { style: styles.title, children: "\u6D4B\u8BD5\u7ED3\u679C" }) }), items.map((item, index) => (_jsx(Item, { index: index, qftp: item, option_ids: option_ids_array }, item.id))), _jsx(View, { style: styles.textContainer, children: _jsxs(Text, { style: styles.text1, children: [epp.score, "\u5206"] }) }), _jsx(TouchableOpacity, { onPress: async () => {
                            navigation.navigate('ExamRecord');
                        }, style: styles.button, children: _jsx(Text, { style: styles.text, children: "\u786E\u5B9A" }) })] }), _jsx(TouchableOpacity, { style: styles.backButton, onPress: () => navigation.navigate('ExamRecord'), children: _jsx(Image, { source: require('../../assets/2.webp'), style: { width: 40, height: 40, opacity: 0.2 } }) })] }));
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        //marginTop: StatusBar.currentHeight || 0,
    },
    scrollViewContent: {
        padding: 0, // 或者根据你的设计调整
    },
    item: {
        backgroundColor: '#ffffff',
        padding: 0,
        marginVertical: 0,
        marginHorizontal: 0,
        // justifyContent: 'center', // 这会垂直居中子视图
        // alignItems: 'center', // 这会水平居中子视图
    },
    image_bg: {
        justifyContent: 'center',
        alignItems: 'center', // 这会水平居中子视图
    },
    title_bg: {
        //flex: 3,
        backgroundColor: '#ffffff',
        padding: 0,
        marginVertical: 0,
        marginHorizontal: 0,
        justifyContent: 'center',
        alignItems: 'center', // 这会水平居中子视图
        //transform: [{ scale: 0.5 }]
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
    },
    desktop_web: {
        width: screenWidth * 0.8,
        height: screenWidth * picRatio * 0.8,
    },
    desktop_app: {
        width: screenWidth,
        height: screenWidth * picRatio,
    },
    xun: {
        fontSize: 24,
    },
    column: {
        flex: 1,
        fontSize: 16,
        textAlign: 'left',
    },
    columnMid: {
        flex: 0.7,
        fontSize: 16,
        textAlign: 'left',
    },
    columnRight: {
        flex: 0.5,
        fontSize: 16,
        textAlign: 'right',
    },
    optionRow: {
        // flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        padding: 10,
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5,
    },
    optionText: {
        flex: 1,
        marginRight: 10,
    },
    radioButton: {
        // 根据需要调整样式，使其与"选择"文本对齐
        // flex: 1,
        height: 20,
        width: 20,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#000',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto', // 将按钮推向行的末端
    },
    radioButtonSelected: {
        height: 10,
        width: 10,
        borderRadius: 5,
        backgroundColor: '#000',
    },
    text1: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 36,
        color: '#4A90E2',
    },
    textContainer: {
        borderBottomWidth: 0, // 控制空隙的大小
        //borderBottomColor: 'red', // 设置空隙颜色为红色
    },
    rowHighlight: {
        backgroundColor: '#66ff00', // 高亮行的背景色
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#007bff',
        padding: 10,
        width: '100%',
        borderRadius: 0, // 可选：添加圆角
    },
    text: {
        color: '#ffffff',
    },
    backButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: 2, // 增加点击区域
    },
    commentSection: {
        marginTop: 0,
        marginBottom: 10,
    },
    commentText: {
        fontSize: 14,
        color: '#333',
        lineHeight: 20,
        flexWrap: 'wrap',
        // 你也可以通过设置宽度限制来确保文本超出后自动换行
        width: '100%', // 或者设置一个特定的宽度
    },
});
export default ExamResultScene;
