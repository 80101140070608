import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { createBottomTabNavigator, } from '@react-navigation/bottom-tabs';
import { HomeScreen } from './HomeScreen/HomeScreen';
import { ProfileScreen } from './ProfileScreen/ProfileScreen';
import { ExamScreen } from './ExamScreen/ExamScreen';
import { AnnounceScreen } from './AnnounceScreen/AnnounceScreen';
import { Text, 
// Button,
View, StyleSheet, Image, SafeAreaView, 
//TouchableOpacity,
Dimensions, } from 'react-native';
import { useAuth } from '../Tools/AuthContext';
// import {AccountNavigator} from './AccountNavigator';
import { LoginModal } from './LoginModal';
import { PRODUCT_VERSION } from '../Tools/config';
//import Modal from 'react-native-modal';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
console.log('xxxxx', windowWidth, windowHeight);
const Tab = createBottomTabNavigator();
const screenOptions = ({ route }) => ({
    tabBarIcon: ({ focused, }) => {
        let iconName = require('../assets/icon2.webp');
        let imageSize = focused ? 30 : 25; // 聚焦时的大小和非聚焦时的大小
        if (route.name === 'Home') {
            iconName = focused
                ? require('../assets/icon2.webp')
                : require('../assets/icon2.webp');
        }
        else if (route.name === 'Profile') {
            iconName = focused
                ? require('../assets/icon1.webp')
                : require('../assets/icon1.webp');
        }
        else if (route.name === 'Exam') {
            iconName = focused
                ? require('../assets/icon0.webp')
                : require('../assets/icon0.webp');
        }
        else if (route.name === 'Announce') {
            iconName = focused
                ? require('../assets/icon3.webp')
                : require('../assets/icon3.webp');
        }
        // 你可以根据需要返回任何组件，这里我们返回一个 Image 组件
        return (_jsx(Image, { source: iconName, style: { width: imageSize, height: imageSize } }));
    },
    //tabBarShowLabel: false, // 隐藏标签文本
    //tabBarStyle: { display: 'none' }, // 完全隐藏标签栏
    headerShown: false, // 这会隐藏所有屏幕的标题栏
});
export const MainNavigator = () => {
    const act = useAuth();
    const [waitLogo, setWaitLogo] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setWaitLogo(false);
        }, 2000);
    }, []);
    if (!act) {
        //console.log("ccccccccccccccccccccccccccccc");
        return _jsx(View, {});
    }
    const { 
    //accessToken,
    //setAccessToken,
    //refreshToken,
    //setRefreshToken,
    needLogin, 
    //setNeedLogin,
    needLogo,
    //setNeedLogo,
     } = act;
    console.log('needLogin', needLogin);
    // let needLoginScene = false;
    // if (accessToken === null || refreshToken === null) {
    //   needLoginScene = true;
    // }
    // if (needLogin === true) {
    //   needLoginScene = true;
    // }
    //下面的逻辑，还要加一个根据needLogo做logoScene是否显示的判断
    if (needLogo || waitLogo) {
        return (_jsxs(View, { style: styles.container, children: [_jsx(Image, { source: require('../assets/1.webp'), style: styles.image }), _jsx(Text, { style: styles.version, children: PRODUCT_VERSION })] }));
    }
    return (_jsxs(SafeAreaView, { style: styles.full, children: [_jsxs(Tab.Navigator, { initialRouteName: "Home", screenOptions: screenOptions, children: [_jsx(Tab.Screen, { name: "Exam", component: ExamScreen, options: { title: '测试' } }), _jsx(Tab.Screen, { name: "Home", component: HomeScreen, options: { title: '题库' } }), _jsx(Tab.Screen, { name: "Profile", component: ProfileScreen, options: { title: '我' } }), _jsx(Tab.Screen, { name: "Announce", component: AnnounceScreen, options: { title: '公告' } })] })
            //AnnounceScreen
            //需要增加一个分页，显示游戏官网地址，app不同平台的下载地址,可能还有一些额外的信息。因为都是静态信息，使用静态网页实现
            , _jsx(View, { children: _jsx(LoginModal, { modalVisible: act.needLogin, setModalVisible: act.setNeedLogin }) })] }));
};
export default MainNavigator;
const styles = StyleSheet.create({
    full: {
        flex: 1,
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        //...StyleSheet.absoluteFillObject, // 使图片填满整个屏幕
        resizeMode: 'center', // 或 'stretch'
        // width: windowWidth,
        // height: windowHeight,
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 22,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    modalButton: {
        marginTop: 10,
        fontSize: 18,
        color: '#007bff',
    },
    version: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        marginBottom: 10,
        fontSize: 14,
        color: 'gray',
        textAlign: 'center',
    },
});
