import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Modal, View, Text, TextInput, 
//Button,
StyleSheet, TouchableOpacity, Platform, } from 'react-native';
import { useAuth } from '../Tools/AuthContext';
import { getToken } from '../Tools/Web/apiPost';
import { RegisterModal } from './RegisterModal';
export const LoginModal = ({ modalVisible, setModalVisible, }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [registerModalVisible, setRegisterModalVisible] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const act = useAuth();
    if (!act) {
        return _jsx(View, {});
    }
    const { 
    // accessToken,
    // setAccessToken,
    // refreshToken,
    // setRefreshToken,
    // needLogin,
    // setNeedLogin,
    // needLogo,
    // setNeedLogo,
    dataFlag, setDataFlag, } = act;
    const handleLogin = async () => {
        // 登录逻辑
        const to = await getToken(username, password, act);
        if (to) {
            console.log('accessToken', to.access);
            console.log('refreshToken', to.refresh);
            setDataFlag(!dataFlag);
            // const decoded = parseJwt(to['access']);
            // console.log('xxxxxxxxxxxxxxxxxxxxxxxx');
            // console.log(decoded);
        }
        setModalVisible(false);
    };
    const handleRegister = async () => {
        setRegisterModalVisible(true);
    };
    const handleClose = async () => {
        setModalVisible(false);
    };
    const toggleSelection = () => {
        setIsSelected(!isSelected);
    };
    const handlePrivate = () => {
        //console.log("xxxxxxxxxxxxxxxxxx");
        //setPrivateModalVisible(true);
        act?.showPrivateModal();
    };
    const handleContract = () => {
        act?.showContractModal();
    };
    return (_jsx(View, { style: styles.centeredView, children: _jsx(Modal, { animationType: "slide", transparent: true, visible: modalVisible, onRequestClose: () => {
                setModalVisible(false);
            }, children: _jsx(View, { style: styles.centeredView, children: _jsxs(View, { style: styles.modalView, children: [_jsx(Text, { style: styles.modalText, children: "\u767B\u5F55" }), _jsx(TextInput, { style: styles.input, placeholder: "\u7528\u6237\u540D", onChangeText: text => setUsername(text), value: username }), _jsx(TextInput, { style: styles.input, placeholder: "\u5BC6\u7801", secureTextEntry: true, onChangeText: text => setPassword(text), value: password }), _jsx(TouchableOpacity, { onPress: handleLogin, style: [styles.button, !isSelected && Platform.OS != 'web' ? styles.buttonDisabled : null], disabled: !isSelected && Platform.OS != 'web', children: _jsx(Text, { style: styles.buttonText, children: "\u63D0\u4EA4" }) }), _jsx(TouchableOpacity, { onPress: handleRegister, style: styles.button, children: _jsx(Text, { style: styles.buttonText, children: "\u6CE8\u518C" }) }), _jsx(TouchableOpacity, { onPress: handleClose, style: styles.button, children: _jsx(Text, { style: styles.buttonText, children: "\u5173\u95ED" }) }), Platform.OS != 'web' && (_jsxs(View, { style: styles.container, children: [_jsx(TouchableOpacity, { onPress: toggleSelection, style: styles.checkboxContainer, children: _jsx(View, { style: [styles.checkboxBase, isSelected && styles.checkboxChecked], children: isSelected && _jsx(Text, { style: styles.checkmark, children: "\u2713" }) }) }), _jsxs(View, { style: styles.textContainer, children: [_jsx(Text, { style: styles.text, children: "\u5DF2\u9605\u8BFB\u5E76\u540C\u610F" }), _jsx(TouchableOpacity, { onPress: handlePrivate, children: _jsx(Text, { style: styles.linkText, children: "\u9690\u79C1\u653F\u7B56" }) }), _jsx(Text, { style: styles.text, children: "\u548C" }), _jsx(TouchableOpacity, { onPress: handleContract, children: _jsx(Text, { style: styles.linkText, children: "\u7528\u6237\u534F\u8BAE" }) })] })] })), _jsx(RegisterModal, { modalVisible: registerModalVisible, setModalVisible: setRegisterModalVisible })] }) }) }) }));
};
const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 0,
    },
    modalView: {
        width: '90%',
        minHeight: '60%',
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        justifyContent: 'center', // 确保内部内容在垂直方向上居中
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#007bff',
        padding: 10,
        width: Platform.OS === 'web' ? '40%' : '80%',
        borderRadius: 0,
        marginBottom: 10,
    },
    input: Platform.select({
        ios: {
            // iOS平台专用样式
            width: '80%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
        android: {
            // Android平台专用样式
            width: '80%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
        web: {
            // Web平台专用样式
            width: '40%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
        default: {
            // 默认样式
            width: '80%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
    }),
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
        fontSize: 18, // 增加文本字体大小
    },
    privateText: {
        fontSize: 16,
        color: '#000',
    },
    buttonDisabled: {
        backgroundColor: '#cccccc', // 灰色背景表示禁用状态
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
    },
    checkboxContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
    },
    checkboxBase: {
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 2,
        borderColor: 'grey',
    },
    checkboxChecked: {
        backgroundColor: '#fff',
    },
    checkmark: {
        color: '#007BFF',
        fontSize: 14,
    },
    textContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    text: {
        fontSize: 14,
        color: '#333',
    },
    linkText: {
        fontSize: 14,
        color: 'blue',
        textDecorationLine: 'underline',
        marginHorizontal: 2, // 增加左右间距以保持一致
    },
    buttonText: {
        color: '#fff',
    },
});
export default LoginModal;
