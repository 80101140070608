import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// PageSelector.tsx
import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import DropdownMenu from './DropdownMenu';
const PageSelector = ({ initialPage, maxPage, onPageChange }) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [selectedPage, setSelectedPage] = useState(initialPage);
    const [buttonLayout, setButtonLayout] = useState({ x: 0, y: 0, width: 0, height: 0 });
    const buttonRef = useRef(null);
    useEffect(() => {
        setSelectedPage(initialPage);
    }, [initialPage]);
    const toggleMenu = () => {
        if (buttonRef.current) {
            buttonRef.current.measure((x, y, width, height, pageX, pageY) => {
                setButtonLayout({ x: pageX, y: pageY, width, height });
                setMenuVisible(!menuVisible);
            });
        }
    };
    const handleSelect = (page) => {
        setSelectedPage(page);
        onPageChange(page); // Notify the parent about the new selected page
    };
    return (_jsxs(View, { style: styles.container, children: [_jsx(TouchableOpacity, { ref: buttonRef, style: styles.button, onPress: toggleMenu, children: _jsx(Text, { style: styles.buttonText, children: selectedPage }) }), _jsx(DropdownMenu, { isVisible: menuVisible, onClose: () => setMenuVisible(false), onSelect: handleSelect, maxPage: maxPage, buttonLayout: buttonLayout })] }));
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        padding: 0,
        backgroundColor: '#007BFF',
        borderRadius: 5,
        width: 50,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center', // Center horizontally
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        textAlign: 'center', // Ensure text is centered
    },
});
export default PageSelector;
