//本文件是所有的Get接口，不需要token验证，也不需要处理token过期的401错误
import { Alert } from 'react-native';
// import {writrToken} from '../token';
import { SERVER_IP_PORT } from '../config';
// export const getOneExamPaperForTest = async (act: AuthContextType) => {
//     const url = SERVER_IP_PORT + '/mahjong/examPaperForTest/';
//     return getTemplate(url, act);
// };
export const getCommentsExpertByQuestionID = async (question_id, act) => {
    const url = SERVER_IP_PORT + '/mahjong/commentsExpertByQuestionID/' + question_id + '/';
    return getTemplate(url, act);
};
export const getCommentsByQuestionID = async (question_id, act) => {
    const url = SERVER_IP_PORT + '/mahjong/commentsByQuestionID/' + question_id + '/';
    return getTemplate(url, act);
};
export const getOptionsByQuestionID = async (question_id, act) => {
    const url = SERVER_IP_PORT + '/mahjong/optionsByQuestionID/' + question_id + '/';
    return getTemplate(url, act);
};
export const getUrlQuestion = async (url, act) => {
    return getTemplate(url, act);
};
export const getQuestion = async (act, page_id) => {
    const url = SERVER_IP_PORT + '/mahjong/question/?page=' + page_id;
    return getTemplate(url, act);
};
export const getQuestionOne = async (id, act) => {
    const url = SERVER_IP_PORT + '/mahjong/question/' + id.toString() + '/';
    return getTemplate(url, act);
};
const getTemplate = async (url, act) => {
    try {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const response = await fetch(url, options);
        if (!response) {
            act.setNeedLogin(true);
            return null;
        }
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        //console.log(data);
        return data;
    }
    catch (error) {
        //console.error('Error fetching data:', error);
        const errorMessage = `Error in getTemplate: ${error}`;
        Alert.alert('警告', errorMessage);
        act.setNeedLogin(true); //出问题就让它重新登录
        return null;
    }
};
