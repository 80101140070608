import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { WebView } from 'react-native-webview';
import { Modal, View, Text, 
//Button,
StyleSheet, TouchableOpacity, Platform, } from 'react-native';
import { useAuth } from '../Tools/AuthContext';
import { writrTag } from '../Tools/token';
// 定义一个变量用于保存 RNExitApp 模块
let RNExitApp = null;
if (Platform.OS !== 'web') {
    // 只有在平台不是 'web' 时才导入 RNExitApp 模块
    RNExitApp = require('react-native-exit-app').default;
}
export const PrivateModal = ({ modalVisible,
//setModalVisible,
 }) => {
    if (!modalVisible)
        return null;
    const act = useAuth();
    if (!act) {
        return _jsx(View, {});
    }
    const { 
    // accessToken,
    // setAccessToken,
    // refreshToken,
    // setRefreshToken,
    // needLogin,
    // setNeedLogin,
    // needLogo,
    // setNeedLogo,
    dataFlag, setDataFlag, } = act;
    const handleRefuse = async () => {
        RNExitApp?.exitApp();
    };
    const handleAgree = async () => {
        await writrTag('true');
        act?.hidePrivateModal();
    };
    return (_jsx(View, { style: styles.centeredView, children: _jsxs(Modal, { animationType: "slide", transparent: true, visible: modalVisible, onRequestClose: () => {
                //act?.hidePrivateModal();
            }, children: [Platform.OS === 'web' ? (_jsx("iframe", { src: "https://aiqipaiwang.com/private/", style: { width: '100%', height: '100%', border: 'none' }, title: "WebView" })) : (_jsx(WebView, { source: { uri: 'https://aiqipaiwang.com/private/' }, style: { flex: 1 } })), _jsx(TouchableOpacity, { onPress: handleRefuse, style: styles.button, children: _jsx(Text, { style: styles.text, children: "\u4E0D\u540C\u610F" }) }), _jsx(TouchableOpacity, { onPress: handleAgree, style: styles.button, children: _jsx(Text, { style: styles.text, children: "\u540C\u610F" }) })] }) }));
};
const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 1)',
    },
    modalView: {
        width: '90%',
        minHeight: '60%',
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        justifyContent: 'center', // 确保内部内容在垂直方向上居中
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#007bff',
        padding: 10,
        width: Platform.OS === 'web' ? '40%' : '100%',
        borderRadius: 0,
        marginBottom: 10,
    },
    text: {
        color: '#ffffff',
    },
    input: Platform.select({
        ios: {
            // iOS平台专用样式
            width: '80%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
        android: {
            // Android平台专用样式
            width: '80%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
        web: {
            // Web平台专用样式
            width: '40%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
        default: {
            // 默认样式
            width: '80%',
            borderWidth: 1,
            borderColor: '#ddd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
    }),
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
        fontSize: 18, // 增加文本字体大小
    },
});
export default PrivateModal;
