import { jsx as _jsx } from "react/jsx-runtime";
// DropdownMenu.tsx
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Modal } from 'react-native';
const DropdownMenu = ({ isVisible, onClose, onSelect, maxPage, buttonLayout }) => {
    const handleSelect = (page) => {
        onSelect(page);
        onClose();
    };
    const renderPages = () => {
        let pages = [];
        for (let i = 1; i <= maxPage; i++) {
            pages.push(_jsx(TouchableOpacity, { onPress: () => handleSelect(i), children: _jsx(Text, { style: styles.menuItem, children: i }) }, i));
        }
        return pages;
    };
    return (_jsx(Modal, { transparent: true, visible: isVisible, animationType: "fade", children: _jsx(TouchableOpacity, { style: styles.overlay, onPress: onClose, children: _jsx(View, { style: [styles.menu, { top: buttonLayout.y + buttonLayout.height, left: buttonLayout.x }], children: _jsx(ScrollView, { children: renderPages() }) }) }) }));
};
const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    menu: {
        position: 'absolute',
        backgroundColor: '#fff',
        padding: 0,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 4,
        elevation: 5,
        maxHeight: 215,
        width: 50,
    },
    scrollViewContent: {
        alignItems: 'flex-end', // Align the content to the right
    },
    menuItem: {
        padding: 0,
        fontSize: 16,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
});
export default DropdownMenu;
