import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { View } from 'react-native';
import React from 'react';
// import {getToken} from '../../Tools/Web/apiPost';
// import {getQuestion, getQuestionOne} from '../../Tools/Web/apiGet';
// import {getMe, logout} from '../../Tools/Web/apiPost';
import { useAuth } from '../../Tools/AuthContext';
//import {writrToken} from '../../Tools/token';
import { createNativeStackNavigator,
//NativeStackScreenProps,
 } from '@react-navigation/native-stack';
import { ExamRecordScene } from '../ExamRecordScene/ExamRecordScene';
import { ExamPaperScene } from '../ExamPaperScene/ExamPaperScene';
import { ExamResultScene } from '../ExamResultScene/ExamResultScene';
import { ExamPaperDailyScene } from '../ExamPaperDailyScene/ExamPaperDailyScene';
import { ExamResultDailyScene } from '../ExamResultDailyScene/ExamResultDailyScene';
const Stack = createNativeStackNavigator();
export const ExamScreen = ({}) => {
    const act = useAuth();
    //const [isLoading, setLoading] = useState(false);
    if (!act) {
        return _jsx(View, {});
    }
    // useEffect(() => {
    //   if (!act.accessToken) {
    //     console.log("accessToken is empty");
    //   }
    // }, []); // 依赖项数组中包含了act，确保当act变化时，这个副作用能够重新执行
    //const {accessToken, setAccessToken, refreshToken, setRefreshToken} = act;
    // if (!accessToken) {
    //   return <View></View>;
    // }
    return (_jsxs(Stack.Navigator, { screenOptions: {
            headerShown: false,
            //headerTransparent: true,
        }, children: [_jsx(Stack.Screen, { name: "ExamRecord", component: ExamRecordScene, options: { title: '' } }), _jsx(Stack.Screen, { name: "ExamPaper", component: ExamPaperScene, options: { title: '' } }), _jsx(Stack.Screen, { name: "ExamResult", component: ExamResultScene, options: { title: '' } }), _jsx(Stack.Screen, { name: "ExamPaperDaily", component: ExamPaperDailyScene, options: { title: '' } }), _jsx(Stack.Screen, { name: "ExamResultDaily", component: ExamResultDailyScene, options: { title: '' } })] }));
};
// export const HomeScreen: React.FC<HomeScreenProps> = ({ navigation }) => {
//   const act: AuthContextType | null = useAuth();
//   const [isLoading, setLoading] = useState(false);
//   return (
//     <Stack.Navigator>
//       <Stack.Screen name="QuestionBank" component={QuestionBankScene} options={{ title: '题库' }} />
//       <Stack.Screen name="QuestionDetail" component={QuestionDetailScene} options={{ title: '详细信息' }}  />
//       {/* 其他屏幕 */}
//     </Stack.Navigator>
//   );
// };
export default ExamScreen;
