import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { View, 
// TextInput,
// Button,
StyleSheet, TouchableOpacity, Text, 
//FlatList,
Image, ScrollView, Platform, } from 'react-native';
import { useAuth } from '../../Tools/AuthContext';
import { submitDailyExamPaper, } from '../../Tools/Web/apiPost';
// import {getQuestion, getUrlQuestion} from '../../Tools/Web/apiGet';
// import {useNavigation} from '@react-navigation/native';
import { SERVER_IP_PORT, 
//OptionProps,
actionMap, paiMap, screenWidth, picRatio, dailyExamSuccess, dailyExamFail, } from '../../Tools/config';
import { getRandomInt } from '../../Tools/utils';
export const ExamPaperDailyScene = ({ navigation, route }) => {
    const depr = route.params;
    const act = useAuth();
    const [selectedOption, setSelectedOption] = useState(null);
    const { question, options, comment } = depr;
    // 对 options 进行排序
    const sortedOptions = options.sort((a, b) => a.order - b.order);
    const handleOptionSelect = (optionId) => {
        console.log('optionId:', optionId);
        setSelectedOption(optionId);
    };
    const handleSubmit = async () => {
        if (selectedOption === null) {
            // 没有选择选项时给出提示
            //alert('请选择一个选项');
            act?.showModal('请选择一个选项');
            return;
        }
        console.log('提交答案:', selectedOption);
        if (act) {
            const depr_new = await submitDailyExamPaper(selectedOption, act);
            //console.log('xxx:', xxx);
            //depr.option_id = selectedOption;
            const index = getRandomInt();
            if (depr_new.options[0].id == selectedOption) {
                depr_new.message = dailyExamSuccess[index];
            }
            else {
                depr_new.message = dailyExamFail[index];
            }
            //act.showModal(depr_new.message);
            navigation.navigate('ExamResultDaily', depr_new);
        }
    };
    return (_jsxs(View, { style: styles.container, children: [_jsxs(ScrollView, { contentContainerStyle: styles.scrollViewContent, children: [_jsx(View, { style: styles.title_bg, children: _jsx(Text, { style: styles.title, children: question.title }) }), question.description && (_jsx(View, { style: styles.description, children: _jsx(Text, { style: styles.descriptionText, children: question.description }) })), question.image_desktop && (_jsx(View, { style: styles.image_bg, children: _jsx(Image, { source: { uri: SERVER_IP_PORT + question.image_desktop }, style: Platform.OS === 'web' ? styles.desktop_web : styles.desktop_app }) })), _jsxs(View, { style: styles.optionRow, children: [_jsx(Text, { style: styles.column, children: "\u64CD\u4F5C" }), _jsx(Text, { style: styles.columnMid, children: "\u724C" }), _jsx(Text, { style: styles.columnRight, children: "\u9009\u62E9" })] }), sortedOptions.map(option => (_jsxs(View, { style: styles.optionRow, children: [_jsx(Text, { style: styles.column, children: actionMap.get(option.action) }), _jsxs(Text, { style: styles.columnMid, children: [paiMap.get(option.pai1), " ", paiMap.get(option.pai2)] }), _jsx(View, { style: styles.columnRight, children: _jsx(TouchableOpacity, { style: styles.radioButton, onPress: () => handleOptionSelect(option.id), children: selectedOption === option.id && (_jsx(View, { style: styles.radioButtonSelected })) }) })] }, option.id))), _jsx(TouchableOpacity, { onPress: handleSubmit, style: styles.button, children: _jsx(Text, { style: styles.text, children: "\u63D0\u4EA4\u7B54\u6848" }) })] }), _jsx(TouchableOpacity, { style: styles.backButton, onPress: () => navigation.goBack(), children: _jsx(Image, { source: require('../../assets/2.webp'), style: { width: 40, height: 40, opacity: 0.2 } }) })] }));
};
// 样式
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollViewContent: {
        padding: 0,
    },
    title_bg: {
        backgroundColor: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
    },
    description: {
        padding: 10,
    },
    descriptionText: {
        fontSize: 16,
        color: '#333',
    },
    image_bg: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    desktop_web: {
        width: screenWidth * 0.8,
        height: screenWidth * picRatio * 0.8,
    },
    desktop_app: {
        width: screenWidth,
        height: screenWidth * picRatio,
    },
    optionRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        padding: 10,
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5,
    },
    column: {
        flex: 1,
        fontSize: 16,
        textAlign: 'left',
    },
    columnMid: {
        flex: 0.7,
        fontSize: 16,
        textAlign: 'left',
    },
    columnRight: {
        flex: 0.5,
        fontSize: 16,
        textAlign: 'right',
    },
    radioButton: {
        // 根据需要调整样式，使其与"选择"文本对齐
        // flex: 1,
        height: 20,
        width: 20,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#000',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto', // 将按钮推向行的末端
    },
    radioButtonSelected: {
        height: 10,
        width: 10,
        borderRadius: 5,
        backgroundColor: '#000',
    },
    commentSection: {
        marginTop: 20,
        padding: 10,
    },
    commentText: {
        fontSize: 14,
        color: '#333',
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#007bff',
        padding: 10,
        width: '100%',
        borderRadius: 0,
    },
    text: {
        color: '#ffffff',
    },
    backButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: 2,
    },
});
export default ExamPaperDailyScene;
