import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { View, 
// TextInput,
// Button,
StyleSheet, TouchableOpacity, Text, 
//FlatList,
Image, ScrollView, Platform, } from 'react-native';
import { useAuth } from '../../Tools/AuthContext';
import { 
//getToken,
getOneExamPaperForTest, getRandomQuestionIDs, postExamPaper, } from '../../Tools/Web/apiPost';
// import {getQuestion, getUrlQuestion} from '../../Tools/Web/apiGet';
// import {useNavigation} from '@react-navigation/native';
import { SERVER_IP_PORT, 
//OptionProps,
actionMap, paiMap, screenWidth, picRatio, scoreCommentMap, } from '../../Tools/config';
import { numberArray_2_string } from '../../Tools/utils';
import { QUESTION_COUNT_FOR_TEST } from '../../Tools/config';
const Item = ({ index, qftp, onSelectOption }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const sortedOptions = qftp.options.sort((a, b) => a.order - b.order);
    return (_jsxs(View, { style: styles.item, children: [_jsx(View, { style: styles.image_bg, children: _jsx(Image, { source: { uri: SERVER_IP_PORT + qftp.image_desktop }, style: Platform.OS === 'web' ? styles.desktop_web : styles.desktop_app }) }), _jsxs(View, { style: styles.optionRow, children: [_jsx(Text, { style: styles.column, children: "\u64CD\u4F5C" }), _jsx(Text, { style: styles.columnMid, children: "\u724C" }), _jsx(Text, { style: styles.columnRight, children: "\u9009\u62E9" })] }), sortedOptions.map(option => (_jsxs(View, { style: styles.optionRow, children: [_jsx(Text, { style: styles.column, children: actionMap.get(option.action) }), _jsxs(Text, { style: styles.columnMid, children: [paiMap.get(option.pai1), " ", paiMap.get(option.pai2)] }), _jsx(View, { style: styles.columnRight, children: _jsx(TouchableOpacity, { style: styles.radioButton, onPress: () => {
                                setSelectedOption(option.id);
                                onSelectOption(index, option.id);
                            }, children: selectedOption === option.id && (_jsx(View, { style: styles.radioButtonSelected })) }) })] }, option.id)))] }));
};
export const ExamPaperScene = ({ navigation /*route*/ }) => {
    const act = useAuth();
    // if (!act) {
    //   return <Text>This is ExamPaper</Text>;
    // }
    // const {
    //   accessToken,
    //   //setAccessToken,
    //   //refreshToken,
    //   //setRefreshToken,
    //   dataFlag,
    //   setDataFlag,
    // } = act;
    const [items, setItems] = useState([]);
    const [question_ids, setQuestion_ids] = useState([]);
    const [option_ids, setOption_ids] = useState([]);
    const updateOptionIds = (index, newValue) => {
        setOption_ids(currentOptionIds => {
            // 使用Array.from创建currentOptionIds的浅拷贝，或者使用展开运算符[...currentOptionIds]
            const updatedOptionIds = Array.from(currentOptionIds);
            // 更新指定索引的值
            updatedOptionIds[index] = newValue;
            // 返回更新后的数组作为新状态
            return updatedOptionIds;
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            if (act) {
                //const { accessToken, setAccessToken, refreshToken, setRefreshToken } = act;
                const obj = await getRandomQuestionIDs(QUESTION_COUNT_FOR_TEST, act);
                console.log('obj', obj);
                if (!obj) {
                    //navigation.navigate('ExamRecord');     //返回上一个界面
                    return;
                }
                const qids = obj.question_ids;
                setQuestion_ids(qids);
                //应该使先取得question_id列表，然后根据question_id列表取question的具体信息。保存成绩也是保存question_id列表
                const questions_temp = await getOneExamPaperForTest(qids, act);
                console.log('questions_temp', questions_temp);
                if (!questions_temp) {
                    return;
                }
                setItems(questions_temp);
            }
        };
        const question_init = new Array(QUESTION_COUNT_FOR_TEST).fill(-1);
        setOption_ids(question_init); //设置默认值
        setItems([]); //清空题目
        if (act?.accessToken !== '') {
            fetchData();
        }
    }, [act?.dataFlag]);
    const handleSubmit = async () => {
        if (act?.accessToken === '') {
            act.setNeedLogin(true);
            return;
        }
        // 处理按钮点击事件
        console.log(question_ids);
        console.log(option_ids); //记录的这个是反的
        if (act) {
            //const reversedArray: number[] = [...option_ids].reverse();
            const reversedArray = [...option_ids];
            const question_ids_str = numberArray_2_string(question_ids);
            const option_ids_str = numberArray_2_string(reversedArray);
            const examPaper = await postExamPaper(//ExamPaperProps还要返回打败玩家的百分比
            question_ids_str, option_ids_str, act);
            console.log('examPaper', examPaper); //其中含有score
            if (examPaper) {
                //根据examPaper.score_position进行showModal
                let pingjia = scoreCommentMap.get(examPaper.score.toString());
                let message = `您获得了${examPaper.score}分，击败了${examPaper.score_position.toFixed(2)}%玩家，${pingjia}`;
                //act.showModal(message);
                examPaper.message = message;
                act.setDataFlag(!act.dataFlag);
                navigation.replace('ExamResult', examPaper); //需要把examPaper作为参数传入
            }
        }
    };
    return (_jsxs(View, { style: styles.container, children: [_jsxs(ScrollView, { contentContainerStyle: styles.scrollViewContent, children: [_jsx(View, { style: styles.title_bg, children: _jsx(Text, { style: styles.title, children: "\u8BD5\u5377" }) }), items.map((item, index) => (_jsx(Item, { index: index, qftp: item, onSelectOption: updateOptionIds }, item.id))), _jsx(TouchableOpacity, { onPress: handleSubmit, style: styles.button, children: _jsx(Text, { style: styles.text, children: "\u63D0\u4EA4\u7B54\u6848" }) })] }), _jsx(TouchableOpacity, { style: styles.backButton, onPress: () => navigation.goBack(), children: _jsx(Image, { source: require('../../assets/2.webp'), style: { width: 40, height: 40, opacity: 0.2 } }) })] }));
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        //marginTop: StatusBar.currentHeight || 0,
    },
    scrollViewContent: {
        padding: 0, // 或者根据你的设计调整
    },
    item: {
        backgroundColor: '#ffffff',
        padding: 0,
        marginVertical: 0,
        marginHorizontal: 0,
        // justifyContent: 'center', // 这会垂直居中子视图
        // alignItems: 'center', // 这会水平居中子视图
    },
    image_bg: {
        justifyContent: 'center',
        alignItems: 'center', // 这会水平居中子视图
    },
    title_bg: {
        //flex: 3,
        backgroundColor: '#ffffff',
        padding: 0,
        marginVertical: 0,
        marginHorizontal: 0,
        justifyContent: 'center',
        alignItems: 'center', // 这会水平居中子视图
        //transform: [{ scale: 0.5 }]
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
    },
    desktop_web: {
        width: screenWidth * 0.8,
        height: screenWidth * picRatio * 0.8,
    },
    desktop_app: {
        width: screenWidth,
        height: screenWidth * picRatio,
    },
    xun: {
        fontSize: 24,
    },
    column: {
        flex: 1,
        fontSize: 16,
        textAlign: 'left',
    },
    columnMid: {
        flex: 0.7,
        fontSize: 16,
        textAlign: 'left',
    },
    columnRight: {
        flex: 0.5,
        fontSize: 16,
        textAlign: 'right',
    },
    optionRow: {
        // flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        padding: 10,
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 5,
    },
    optionText: {
        flex: 1,
        marginRight: 10,
    },
    radioButton: {
        // 根据需要调整样式，使其与"选择"文本对齐
        // flex: 1,
        height: 20,
        width: 20,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#000',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto', // 将按钮推向行的末端
    },
    radioButtonSelected: {
        height: 10,
        width: 10,
        borderRadius: 5,
        backgroundColor: '#000',
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#007bff',
        padding: 10,
        width: '100%',
        borderRadius: 0, // 可选：添加圆角
    },
    text: {
        color: '#ffffff',
    },
    backButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: 2, // 增加点击区域
    },
});
export default ExamPaperScene;
