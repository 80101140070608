import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//import {StyleSheet} from 'react-native';
import React from 'react';
// import {getToken} from '../../Tools/Web/apiPost';
// import {getQuestion, getQuestionOne} from '../../Tools/Web/apiGet';
// import {getMe, logout} from '../../Tools/Web/apiPost';
//import {useAuth, AuthContextType} from '../../Tools/AuthContext';
//import {writrToken} from '../../Tools/token';
import { createNativeStackNavigator,
//NativeStackScreenProps,
 } from '@react-navigation/native-stack';
import { QuestionBankScene } from '../QuestionBankScene/QuestionBankScene';
import { QuestionDetailScene } from '../QuestionDetailScene/QuestionDetailScene';
const Stack = createNativeStackNavigator();
export const HomeScreen = ({}) => {
    // const act: AuthContextType | null = useAuth();
    // const [isLoading, setLoading] = useState(false);
    return (_jsxs(Stack.Navigator, { screenOptions: {
            headerShown: false,
            //headerTransparent: true,
        }, children: [_jsx(Stack.Screen, { name: "QuestionBank", component: QuestionBankScene, options: { title: '' } }), _jsx(Stack.Screen, { name: "QuestionDetail", component: QuestionDetailScene, options: { title: '' } })] }));
};
// const styles = StyleSheet.create({
//   loadingOverlay: {
//     ...StyleSheet.absoluteFillObject, // This ensures the overlay covers the entire screen
//     justifyContent: 'center',
//     alignItems: 'center',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)', // For semi-transparent background
//   },
// });
export default HomeScreen;
