import { Alert } from 'react-native';
import { writrToken } from '../token';
import { SERVER_IP_PORT } from '../config';
// 定义一个异步函数进行网络请求
const fetchWithTimeout = (url, options, timeout = 5000) => {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), timeout)),
    ]);
};
//
//注册
export async function register(username, email, password) {
    let url = SERVER_IP_PORT + '/users/register/';
    try {
        const response = await fetchWithTimeout(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                email,
                password,
            }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        //const data = await response.json();
        // 处理成功的响应，例如跳转到登录页面或显示成功消息
        //Alert.alert("注册成功", "请前往登录");
        return true;
    }
    catch (error) {
        console.error('注册失败:', error);
        //Alert.alert("注册失败", "请检查您的输入");
        return false;
    }
}
// 删除账户
export async function deleteAccount(act) {
    let url = SERVER_IP_PORT + '/users/delete/';
    try {
        const { accessToken, } = act;
        const response = await fetchWithTimeout(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`, // 添加 Authorization 头部
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        // 删除成功，可以添加逻辑处理，如通知用户或跳转到主页
        //Alert.alert("账户已删除", "您的账户已成功删除。");
        return true;
    }
    catch (error) {
        console.error('删除账户失败:', error);
        //Alert.alert("删除失败", "无法删除账户，请稍后重试。");
        return false;
    }
}
export async function logout(act) {
    try {
        const { 
        //accessToken,
        setAccessToken, 
        //refreshToken,
        setRefreshToken,
        //needLogin,
        //setNeedLogin,
         } = act;
        setAccessToken('');
        setRefreshToken('');
        //setNeedLogin(true);
        const response = await writrToken('', '');
        return response;
    }
    catch (error) {
        const errorMessage = `Error in logout: ${error}`;
        Alert.alert('警告', errorMessage);
        return null;
    }
}
//登录
export async function getToken(username, password, act) {
    try {
        const { 
        //accessToken,
        setAccessToken, 
        //refreshToken,
        setRefreshToken, 
        //needLogin,
        setNeedLogin, } = act;
        // 定义请求的 URL 和登录凭据
        const url = SERVER_IP_PORT + '/users/token/';
        const credentials = {
            username: username,
            password: password,
        };
        //console.log(' error  0');
        // 发送 POST 请求
        const response = await fetchWithTimeout(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials), // 将凭据转换为 JSON 字符串
        });
        //console.log(' error  1');
        // 检查响应状态
        if (!response.ok) {
            console.log(' error status ', response.status);
            throw new Error(`Error: ${response.status}`);
        }
        // 解析 JSON 响应
        const data = await response.json();
        //写入内存
        //console.log(' error  2');
        setAccessToken(data.access);
        console.log('access token ', data.access);
        setRefreshToken(data.refresh);
        setNeedLogin(false);
        //写入硬盘
        await writrToken(data.access, data.refresh);
        return data;
    }
    catch (error) {
        //console.error('Error fetching data: ', error);
        const errorMessage = `Error in getToken: ${error}`;
        Alert.alert('警告', errorMessage);
        return null;
    }
}
//创造一条数据，用Post，要token
//title
//body
//status  p
//在app或者web中，因为有image字段，只能一张张的处理，在python中则可以批量上传
//根据author_id取所有的ExamPaper，方便本地做表现。这个author_id从accesstoken中获得,就是user_id
export const getExamPapersByAuthorID = async (author_id, act) => {
    const url = SERVER_IP_PORT + '/mahjong/examPapersByAuthorID/';
    console.log('start getExamPapersByAuthorID');
    const postData = {
        author_id: author_id,
    };
    return postDataWithTokenTemplate(url, postData, 'POST', act);
};
export const getRandomQuestionIDs = async (count, act) => {
    const url = SERVER_IP_PORT + '/mahjong/randomQuestionIDs/';
    console.log('start getRandomQuestionIDs');
    const postData = {
        count: count,
    };
    return postDataWithTokenTemplate(url, postData, 'POST', act);
};
export const getOneExamPaperForTest = async (question_ids, act) => {
    const url = SERVER_IP_PORT + '/mahjong/examPaperForTest/';
    console.log('start getOneExamPaperForTest');
    const postData = {
        question_ids: question_ids,
    };
    return postDataWithTokenTemplate(url, postData, 'POST', act);
};
export const getMe = async (act) => {
    const url = SERVER_IP_PORT + '/users/me/';
    console.log('start getMe');
    return postDataWithTokenTemplate(url, null, 'GET', act);
    // let response = await fetch('https://facebook.github.io/react-native/movies.json')
    // return response
};
// export const postArticleOne = async (title: string, body: string, status: string, act: AuthContextType) => {
//     const url = SERVER_IP_PORT + '/v1/articles/';
//     //return getTemplate(url, act);
//     const postData = {
//         title: title,
//         body: body,
//         status: status
//     };
//     return postDataWithTokenTemplate(url, postData, 'POST', act);
// };
//export const postExamPaper  question_ids, select_ids
export const postExamPaper = async (question_ids, option_ids, act) => {
    const url = SERVER_IP_PORT + '/mahjong/examPaper/';
    const postData = {
        question_ids: question_ids,
        option_ids: option_ids,
    };
    return postDataWithTokenTemplate(url, postData, 'POST', act);
};
export const postCommentExpert = async (question_id, content, act) => {
    const url = SERVER_IP_PORT + '/mahjong/commentExpert/';
    const postData = {
        question: question_id,
        content: content,
    };
    return postDataWithTokenTemplate(url, postData, 'POST', act);
};
export const postComment = async (question_id, content, act) => {
    const url = SERVER_IP_PORT + '/mahjong/comment/';
    const postData = {
        question: question_id,
        content: content,
    };
    return postDataWithTokenTemplate(url, postData, 'POST', act);
};
export const dailyExamPaper = async (act) => {
    const url = SERVER_IP_PORT + '/mahjong/dailyExamPaper/';
    const postData = {};
    return postDataWithTokenTemplate(url, postData, 'POST', act);
};
export const submitDailyExamPaper = async (option_id, act) => {
    const url = SERVER_IP_PORT + '/mahjong/submitDailyExamPaper/';
    const postData = {
        option: option_id,
    };
    return postDataWithTokenTemplate(url, postData, 'POST', act);
};
const postDataWithTokenTemplate = async (url, postData, method, act) => {
    // 尝试使用当前 token 发送请求
    const { 
    //accessToken,
    setAccessToken, 
    //refreshToken,
    //setRefreshToken,
    //needLogin,
    setNeedLogin, dataFlag, setDataFlag, } = act;
    try {
        const response = await fetchWrapper(url, postData, method, act.accessToken);
        //console.log('error 0');
        if (response.status === 401 && act.refreshToken) {
            //处理accesstoken过期的情况
            const accessTokenNew = await refreshAccessToken(act.refreshToken); // 刷新 token
            if (accessTokenNew) {
                //使用新的 token重试请求
                //写入内存
                setAccessToken(accessTokenNew);
                //写入硬盘
                await writrToken(accessTokenNew, act.refreshToken);
                const responseX = await fetchWrapper(url, postData, method, accessTokenNew);
                if (responseX.ok) {
                    return responseX.json();
                }
                else {
                    logout(act);
                    setDataFlag(!dataFlag); //通知更新显示
                    setNeedLogin(true);
                    return null;
                }
            }
            else {
                //刷新access token失败，说明refresh token也过期，需要重新登录
                logout(act);
                setDataFlag(!dataFlag); //通知更新显示
                setNeedLogin(true);
                return null;
            }
        }
        else {
            if (response.ok) {
                return response.json();
            }
            else {
                logout(act);
                setDataFlag(!dataFlag); //通知更新显示
                setNeedLogin(true);
                return null;
            }
        }
    }
    catch (error) {
        //console.error('Error refreshing token:', error);
        const errorMessage = `Error in postDataWithTokenTemplate: ${error}`;
        Alert.alert('警告', errorMessage);
        logout(act);
        setDataFlag(!dataFlag); //通知更新显示
        setNeedLogin(true); //出问题就让它重新登录
        return null;
    }
};
const fetchWrapper = async (url, postData, method, accessToken) => {
    const options = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: postData ? JSON.stringify(postData) : undefined,
    };
    //console.log('error 00');
    const response = await fetch(url, options);
    //console.log('error 11');
    return response;
};
export const refreshAccessToken = async (refreshToken) => {
    const url = SERVER_IP_PORT + '/users/token/refresh/';
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
    });
    if (!response.ok) {
        //throw new Error(`HTTP error! status: ${response.status}`);
        return null;
    }
    const data = await response.json();
    //Alert.alert('Alert', 'refresh accessToken success');
    return data.access; // 假设返回的 JSON 对象中包含新的 access token
};
