import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
const CustomModal2 = ({ modalVisible, modalMessage, handleOK, handleCancel }) => {
    if (!modalVisible)
        return null;
    return (_jsx(Modal, { visible: modalVisible, transparent: true, animationType: "slide", children: _jsx(View, { style: styles.centeredView, children: _jsxs(View, { style: styles.modalView, children: [_jsx(Text, { style: styles.modalText, children: modalMessage }), _jsxs(View, { style: styles.buttonContainer, children: [_jsx(TouchableOpacity, { style: styles.button, onPress: handleOK, children: _jsx(Text, { style: styles.buttonText, children: "\u786E\u5B9A" }) }), _jsx(TouchableOpacity, { style: styles.button, onPress: handleCancel, children: _jsx(Text, { style: styles.buttonText, children: "\u53D6\u6D88" }) })] })] }) }) }));
};
const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    button: {
        backgroundColor: '#2196F3',
        borderRadius: 20,
        padding: 10,
        elevation: 2,
        width: '45%',
        alignItems: 'center',
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
});
export default CustomModal2;
