/**
 * @format
 */

import {AppRegistry} from 'react-native';
import App from './resource/App';
import {name as appName} from './app.json';

AppRegistry.registerComponent(appName, () => App);

var  tag = document.getElementById("react-root")

AppRegistry.runApplication(appName, {
    rootTag: tag
  });