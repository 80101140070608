import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//import * as React from 'react';
import React, { useEffect } from 'react';
import { View, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import // createNativeStackNavigator,
 
// NativeStackScreenProps,
'@react-navigation/native-stack';
import //createBottomTabNavigator,
 
// BottomTabNavigationProp,
// BottomTabScreenProps,
'@react-navigation/bottom-tabs';
//import { getToken } from './Tools/webAPI';
//import {BottomTabParamList} from './Tools/config';
// import {HomeScreen} from './Screen/HomeScene/HomeScreen';
// import {ProfileScreen} from './Screen/ProfileScene/ProfileScreen';
import { AuthProvider, useAuth } from './Tools/AuthContext';
import { MainNavigator } from './Screen/MainNavigator';
import { readToken, readTag } from './Tools/token';
const MyComponent = () => {
    //只是为了读数据
    const act = useAuth();
    // if (act === null) {
    //   return <View />;
    // }
    // const {
    //   //accessToken,
    //   setAccessToken,
    //   //refreshToken,
    //   setRefreshToken,
    //   //needLogin,
    //   //setNeedLogin,
    //   needLogo,
    //   setNeedLogo,
    // } = act;
    useEffect(() => {
        const loadData = async () => {
            try {
                if (act) {
                    const savedDate = await readToken(); //从硬盘读取数据写入
                    if (savedDate) {
                        if (savedDate.accessToken) {
                            act.setAccessToken(savedDate.accessToken);
                        }
                        else {
                            act.setAccessToken('');
                        }
                        if (savedDate.refreshToken) {
                            act.setRefreshToken(savedDate.refreshToken);
                        }
                        else {
                            act.setRefreshToken('');
                        }
                    }
                    act.setNeedLogo(false); //无论如何，都要把logo隐藏
                    console.log('game start');
                    //读playTag，是否是第一次玩.空或false就是第一次玩，true就不是
                    const tag = await readTag();
                    if (tag) {
                        if (tag.playTag == 'true') {
                            //已玩过，什么都不做
                        }
                        else {
                            //await writrTag('true');
                            if (Platform.OS !== 'web') {
                                act.showPrivateModal();
                            }
                        }
                    }
                }
            }
            catch (error) {
                console.log('error in loadData');
            }
        };
        loadData();
        // setTimeout(() => {
        //   loadData();
        // }, 5000);
    }, []);
    //console.log('needLogo ', needLogo);
    //根据needLogo来决定是否显示logo图
    return _jsx(View, {});
}; // 空依赖数组确保这段代码只运行一次
//const Tab = createBottomTabNavigator<BottomTabParamList>();
const MyStack = () => {
    // const act: AuthContextType | null = useAuth();
    // if (!act) {
    //   console.log("ssssssssssssssssss");
    //   return <View></View>;
    // };
    // const { accessToken, setAccessToken,
    //   refreshToken, setRefreshToken,
    //   needLogin, setNeedLogin,
    //   needLogo, setNeedLogo } = act;
    return (_jsxs(AuthProvider, { children: [_jsx(MyComponent, {}), _jsx(NavigationContainer, { children: _jsx(MainNavigator, {}) })] }));
};
export default MyStack;
