// import { decode } from 'react-native-base64';
// import {jwtDecode} from 'jwt-decode';
import { Base64 } from 'js-base64';
import { SERVER_IP_PORT } from './config';
export function formatDateTime(isoString) {
    const date = new Date(isoString);
    // 构建日期部分
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    // 构建时间部分
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    // 返回格式化的日期和时间字符串
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export function parseJwt(token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/') +
            '=='.slice(0, (3 * base64Url.length) % 4);
        const payload = Base64.decode(base64);
        return JSON.parse(payload);
    }
    catch (e) {
        console.error('Failed to parse token', e);
        return null;
    }
}
export function numberArray_2_string(array) {
    const commaSeparatedString = array.join(',');
    return commaSeparatedString;
}
export function string_2_numberArray(commaSeparatedString) {
    const array = commaSeparatedString.split(',');
    const numberArray = array.map(Number); //使用.map()转换为数字数组
    return numberArray;
}
export function parsePageURL(page_url) {
    // 手动解析查询字符串
    const queryString = page_url.split('?')[1];
    if (!queryString) {
        return 1; // 如果没有查询字符串，返回默认值 1
    }
    const params = queryString.split('&');
    for (const param of params) {
        const [key, value] = param.split('=');
        if (key === 'page') {
            const pageNumber = parseInt(value, 10);
            return isNaN(pageNumber) ? 1 : pageNumber;
        }
    }
    return 1; // 如果没有 page 参数，则返回默认值 1
}
//return {accessToken, refreshToken};
export function composePageURL(page_id, max) {
    const page_id_prev = page_id - 1;
    const page_id_next = page_id + 1;
    var pageURL_prev = "";
    var pageURL_next = "";
    if (page_id_prev < 1) {
        pageURL_prev = null;
    }
    else {
        pageURL_prev = SERVER_IP_PORT + '/mahjong/question/?page=' + page_id_prev;
    }
    if (page_id_next > max) {
        pageURL_next = null;
    }
    else {
        pageURL_next = SERVER_IP_PORT + '/mahjong/question/?page=' + page_id_next;
    }
    return { pageURL_prev, pageURL_next };
}
export function parseStartEndPage(pageURL_prev, pageURL_next, max_page) {
    var start_page = 1;
    var end_page = 1;
    if (pageURL_prev == null) {
        start_page = 1;
    }
    else {
        start_page = parsePageURL(pageURL_prev) + 1;
    }
    if (pageURL_next == null) {
        end_page = max_page;
    }
    else {
        end_page = parsePageURL(pageURL_next) - 1;
    }
    return { start_page, end_page };
}
export function getRandomInt() {
    return Math.floor(Math.random() * 5);
}
//{ token_type: 'access',
//exp: 1708071027,
//iat: 1708070967,
//jti: '6177babfe4d34351bd58189ae50d84d3',
//user_id: 2 }
