import AsyncStorage from '@react-native-async-storage/async-storage';
export const writrToken = async (accessToken, refreshToken) => {
    try {
        await AsyncStorage.setItem('accessToken', accessToken);
        await AsyncStorage.setItem('refreshToken', refreshToken);
    }
    catch (e) {
    }
};
export const readToken = async () => {
    try {
        const accessToken = await AsyncStorage.getItem('accessToken');
        const refreshToken = await AsyncStorage.getItem('refreshToken');
        return { accessToken, refreshToken };
    }
    catch (e) {
        return null;
    }
};
export const writrTag = async (playTag) => {
    try {
        await AsyncStorage.setItem('playTag', playTag);
    }
    catch (e) {
    }
};
export const readTag = async () => {
    try {
        const playTag = await AsyncStorage.getItem('playTag');
        return { playTag };
    }
    catch (e) {
        return null;
    }
};
