import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet, } from 'react-native';
const CustomModal = ({ modalVisible, modalMessage, handleClose, ...otherProps }) => {
    if (!modalVisible)
        return null;
    return (_jsx(Modal, { visible: modalVisible, transparent: true, ...otherProps, children: _jsx(View, { style: styles.centeredView, children: _jsxs(View, { style: styles.modalView, children: [_jsx(Text, { style: styles.modalText, children: modalMessage }), _jsx(TouchableOpacity, { style: styles.buttonClose, onPress: handleClose, children: _jsx(Text, { style: styles.textStyle, children: "\u5173\u95ED" }) })] }) }) }));
};
const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
    buttonClose: {
        backgroundColor: '#2196F3',
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
});
export default CustomModal;
